// Background colours
$backgroundColour: #ffffff;
$codeBackgroundColour: #fafafa;
$featureBackgroundColour: #f9fafa;
$accentColour: #f51801;

// Text colours
$headingColour: #242e2b;
$bodyColour: #384743;
$linkColour: #f51801;
$hoverColour: #da5809;
$focusColour: #fa407a;
$captionColour: #a8adac;
$white: #ffffff;

// Typography
$bodytype: (
  font-family: 'Lato, Verdana, "Hiragino Kaku Gothic ProN", Meiryo, "游ゴシック", YuGothic, "Helvetica Neue", Helvetica, Arial, sans-serif',
  regular: 400,
  bold: 700,
  italic: italic,
  cap-height: 0.75
);

$headingtype: (
  font-family: 'Lato, Verdana, "Hiragino Kaku Gothic ProN", Meiryo, "游ゴシック", YuGothic, "Helvetica Neue", Helvetica, Arial, sans-serif',
  regular: 400,
  bold: 700,
  cap-height: 0.75
);

$monospacetype: (
  font-family: 'Menlo, monospace',
  regular: 400,
  cap-height: 0.68
);

// Font import, if you're using a non-standard web font
@import url('https://fonts.googleapis.com/css?family=Lato:400,700');
